'use client';

import { FC, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { inIframe } from '@/utils/element';

/* eslint-disable @next/next/no-img-element */

type MarkdownImageProps = {
  src: string;
};

const MarkdownImage: FC<MarkdownImageProps> = ({ src }) => {
  const [showBigger, setShowBigger] = useState(false);

  function openImage() {
    const isInIframe = inIframe();

    if (isInIframe) {
      window.parent.postMessage({ key: 'open-image-modal', value: src }, '*');
    } else {
      setShowBigger(true);
    }
  }

  function toggleImage() {
    setShowBigger(false);
  }

  return (
    <div>
      <div
        style={{
          boxShadow:
            '0px 6px 6px -3px rgba(20, 20, 20, 0.05), 0px 3px 3px -1.5px rgba(20, 20, 20, 0.05), 0px 1px 1px -0.5px rgba(20, 20, 20, 0.05), 0px 0px 0px 1px rgba(20, 20, 20, 0.06)',
        }}
        className="my-3 inline-flex h-auto max-h-[208px] w-auto max-w-full overflow-hidden rounded-xl bg-white p-1"
      >
        <img
          src={src}
          onClick={openImage}
          className="max-h-[200px] max-w-full cursor-pointer rounded-lg object-cover"
          alt="Markdown Image"
        />
      </div>
      {showBigger && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={toggleImage}
        >
          <button
            className="absolute right-3 top-3 rounded-full p-1 font-bold text-white"
            onClick={toggleImage}
          >
            <XMarkIcon height={20} width={20} className="fill-white" />
          </button>
          <div className="mx-4 flex h-full items-center justify-center">
            <img
              src={src}
              alt="Image"
              className="h-auto max-h-[90%] rounded-lg border border-gray-200 bg-white object-contain p-4"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarkdownImage;
