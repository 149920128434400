'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { MessageType } from '@prisma/client';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';

import { sendEventToMake } from '@/app/actions/sendEventToMake';
import { sendEventToRetool } from '@/app/actions/sendEventToRetool';
import { useModalContext } from '@/context/modalContext';

import MessageBubbleIcon from '../icons/message-bubble-icon.component';
import AIActionDebug from './ai-action-debug.component';
import LearnMore from './learn-more.component';
import MessageMarkdown, {
  MessageMarkdownProps,
} from './message-markdown.component';
import QualityBadge, { QualityBadgeType } from './quality-badge.component';
import ResolutionForm, {
  ResolutionFormEvent,
} from './resolution-form.component';
import type { MessageFunctionLog, PublicLinks } from './types';

export type MessegeBoxVariant = 'default' | 'minimal';
export type MessageBoxStyle = {
  backgroundColor: string;
  textColor: string;
  borderColor: string;
};

export type MessageBoxProps = MessageMarkdownProps & {
  chatbotId?: string;
  chatbotWorkspaceId?: string;
  messageId?: string;
  variant: MessegeBoxVariant;
  position: 'left' | 'right';
  style: MessageBoxStyle;
  activeChatId?: string;
  source?: PublicLinks;
  showOnlyWebsiteSource?: boolean;
  showResolutionForm?: boolean;
  showAddQAButton?: boolean;
  qualityBadge?: QualityBadgeType;
  showHumanSupportButton?: boolean;
  isQuotaLimitReached?: boolean;
  aiTyping?: boolean;
  aIFunctionLogs?: MessageFunctionLog;
  tooltipTime?: string;
};

const MessageBox: FC<MessageBoxProps> = ({
  chatbotId,
  chatbotWorkspaceId,
  messageId,
  children,
  variant,
  type,
  position,
  activeChatId,
  source,
  showOnlyWebsiteSource = true,
  style,
  showResolutionForm = false,
  showAddQAButton = false,
  qualityBadge,
  showHumanSupportButton = false,
  isQuotaLimitReached,
  aiTyping = false,
  aIFunctionLogs,
  tooltipTime,
}) => {
  const queryClient = useQueryClient();
  const { openModal } = useModalContext();

  const [_showResolutionForm, setShowResolutionForm] =
    useState<boolean>(showResolutionForm);

  const handleSendResolutionFormEvent = async (
    messageId: string,
    event: ResolutionFormEvent,
  ) => {
    await sendEventToRetool({
      workspaceId: chatbotWorkspaceId,
      event,
      chatbotId,
      messageId,
    });

    const eventLabel =
      event === 'AIHelped'
        ? 'That helped 👍'
        : event === 'AIDidNotHelp'
          ? "That didn't help 👎"
          : 'Talk to human 🙋‍♂';

    await sendEventToMake({
      eventType: 'resolution_form',
      value: `User clicked ${eventLabel} button.\nChat id: ${activeChatId}.\nMessage: ${children}`,
      chatbotId,
    });
  };

  const handleClickAddQAButton = () => {
    openModal<string>({
      id: 'add-qa-from-message',
      data: {
        chatbotId,
        messageId,
      },
      onSuccess: (id) => {
        queryClient.invalidateQueries([`${activeChatId}_messages`]);
      },
    });
  };

  useEffect(() => {
    setShowResolutionForm(showResolutionForm);
  }, [showResolutionForm]);

  const messageSources = useMemo(() => {
    if (!source || !source.length) return null;

    if (!showOnlyWebsiteSource) return source;

    return source.filter((item) => item.type === 'WEBSITE');
  }, [showOnlyWebsiteSource, source]);

  const renderLearnMore = messageSources && !!messageSources.length;

  const renderAddQAButton = showAddQAButton && typeof messageId === 'string';

  if (variant === 'minimal') {
    return (
      <>
        <div
          id="message"
          style={{
            background:
              style.backgroundColor ||
              'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
            color: style.textColor,
            border: `1px solid ${style.borderColor}`,
          }}
          className={cn(
            'w-fit max-w-fit break-words rounded-[15px] text-sm leading-5',
            {
              ['mr-auto w-full']: position === 'left',
              ['ml-auto']: position === 'right',
              ['max-w-[75%]']: type === MessageType.IMAGE,
              ['max-w-full']: type === MessageType.TEXT,
            },
          )}
          data-tooltip-id="messenger-time-tooltip"
          data-tooltip-content={tooltipTime}
          data-tooltip-offset={4}
        >
          <MessageMarkdown
            className={cn('px-[18px] pt-[15px]', {
              ['pb-[15px]']: !_showResolutionForm || isQuotaLimitReached,
            })}
            type={type}
            aiTyping={aiTyping}
          >
            {children}
          </MessageMarkdown>

          {_showResolutionForm && !isQuotaLimitReached ? (
            <ResolutionForm
              variant={variant}
              activeChatId={activeChatId}
              style={style}
              handleClickButton={async (id, event) => {
                setShowResolutionForm(false);
                await handleSendResolutionFormEvent(id, event);
              }}
              showHumanSupportButton={showHumanSupportButton}
            />
          ) : null}

          {renderLearnMore ? (
            <LearnMore
              variant={variant}
              position={position}
              source={messageSources}
              style={style}
            />
          ) : null}
        </div>

        {aIFunctionLogs && !!aIFunctionLogs?.length
          ? aIFunctionLogs.map((actionLog) => (
              <AIActionDebug
                key={actionLog.id}
                position={position}
                style={{
                  backgroundColor: style.backgroundColor,
                  textColor: style.textColor,
                  borderColor: style.borderColor,
                }}
                className="[&>div]:rounded-[15px]"
                actionName={actionLog.name}
                actionId={actionLog.id}
              />
            ))
          : null}
      </>
    );
  }

  return (
    <>
      <div
        id="message"
        style={{
          background:
            style.backgroundColor ||
            'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
          color: style.textColor,
          border: `1px solid ${style.borderColor}`,
        }}
        className={cn(
          'max-w w-fit break-words rounded-lg px-[15px] py-3 text-sm',
          {
            ['mr-auto']: position === 'left',
            ['ml-auto']: position === 'right',
            ['max-w-[75%]']: type === MessageType.IMAGE,
            ['max-w-full']: type === MessageType.TEXT,
          },
        )}
      >
        <MessageMarkdown type={type} aiTyping={aiTyping}>
          {children}
        </MessageMarkdown>

        {qualityBadge ? (
          <div className="mt-2.5 flex w-full items-center justify-start">
            <QualityBadge value={qualityBadge} />
          </div>
        ) : null}
      </div>

      {aIFunctionLogs && !!aIFunctionLogs?.length
        ? aIFunctionLogs.map((actionLog) => (
            <AIActionDebug
              key={actionLog.id}
              position={position}
              style={{
                backgroundColor: style.backgroundColor,
                textColor: style.textColor,
                borderColor: style.borderColor,
              }}
              actionName={actionLog.name}
              actionId={actionLog.id}
            />
          ))
        : null}

      {renderAddQAButton ? (
        <div
          className="mt-2 flex w-full items-center justify-end"
          onClick={handleClickAddQAButton}
        >
          <button className="text-bluesmart flex items-center text-xs font-medium">
            <MessageBubbleIcon className="mr-1.5 h-[16px] w-[16px]" />
            <span>Save as a QA Data Source</span>
          </button>
        </div>
      ) : null}

      {renderLearnMore ? (
        <LearnMore
          className="mt-2"
          variant={variant}
          position={position}
          style={style}
          source={messageSources}
        />
      ) : null}

      {_showResolutionForm ? (
        <ResolutionForm
          variant={variant}
          activeChatId={activeChatId}
          style={style}
          handleClickButton={async (id, event) => {
            setShowResolutionForm(false);
            await handleSendResolutionFormEvent(id, event);
          }}
          showHumanSupportButton={showHumanSupportButton}
        />
      ) : null}
    </>
  );
};

export default MessageBox;
